.homePage {
	width: 100%;
	padding-top: 0px;
	text-align: center;
	background-color: white;
	-webkit-animation: fade-in 1s linear;
	animation: fade-in 1s linear;
}

.sideBar {
	height: auto;
}

.content-counter {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: space-around;
	background-color: deepskyblue;
	border-radius: 0px 0px 20px 20px;
	box-shadow: rgb(216, 216, 216) 0px 10px 50px 0px;
	padding-bottom: 15px;
	margin: 0;
	font-size: 25px;
	line-height: 10px;
	color: aliceblue;
}

.content-counter span {
	font-size: 25px;
}

.albums-container {
	margin-bottom: 50px;
	background-color: transparent;
}

.userInfo {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-top: 70px;
	background-color: rgb(230, 230, 230);
}

#albumTag {
	height: 80px;
	width: 100px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: row-reverse;
}

#addAlbumBtn {
	box-sizing: border-box;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	overflow: hidden;
	color: white;
}

#changeProfileBtn {
	position: absolute;
	margin-top: 70px;
	margin-left: -40px;
	width: 40px;
}
#userPhoto {
	width: 100px;
	height: 100px;
	border-radius: 50px 50px;
}

@media only screen and (min-width: 700px) {
	.homePage {
		width: 100%;
		display: flex;
		justify-content: space-around;
	}
	.sideBar {
		width: 10%;
		margin-left: 2%;
		display: block;
		text-align: center;
	}
	.content-counter {
		width: 100%;
		height: 60px;
		font-size: 15px;
	}
	.content-counter span {
		font-size: 20px;
	}
	.albums-container {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.images-container {
		width: 100%;
		margin: 100px;
	}

	#albumTag {
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column-reverse;
	}
	#addAlbumBtn {
		box-sizing: border-box;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		overflow: hidden;
		position: relative;
		margin-right: auto;
		margin-left: auto;
		margin-top: -40px;
	}
}
