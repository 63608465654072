.edit-page-container {
	position: relative;
	width: 100%;
	padding-top: 50px;
	margin-bottom: 25px;
	text-align: center;
	-webkit-animation: fade-in 1s linear;
	animation: fade-in 1s linear;
}

.imgPreview {
	width: 100%;
	margin-top: 20px;
}

.imageEditForm {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding-top: 10px;
}

.imageEditForm label {
	font-size: 30px;
	margin-bottom: 10px;
}

.imageEditForm input {
	width: 100%;
	height: 50px;
	box-sizing: border-box;
	text-align: center;
	font-size: 25px;
	border: 1px solid black;
}

.imageEditForm button {
	width: 90%;
	margin-top: 15px;
	height: 50px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	font-size: 20px;
	border: 1px solid black;
	background-color: transparent;
}
.imageEditForm select {
	width: 100%;
	height: 35px;
	font-size: 20px;
	border: 1px solid black;
}

#captionTextarea {
	height: 150px;
	width: 100%;
	border: 1px solid black;
	font-size: 20px;
	padding: 10px;
	box-sizing: border-box;
}

.switch-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
#switchText {
	font-size: 20px;
}
.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 25px;
	background-color: rgb(165, 165, 165);
	border-radius: 20px;
	border: 1px solid black;
}

.switch::after {
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: rgb(255, 255, 255);
	top: 2px;
	left: 2px;
	transition: all 0.3s;
}

.checkbox:checked + .switch::after {
	left: 27px;
}
.checkbox:checked + .switch {
	background-image: linear-gradient(to right, rgb(0, 255, 34), rgb(13, 0, 255));
}
.checkbox {
	display: none;
}

@media only screen and (min-width: 700px) {
	.edit-page-container {
		display: flex;
		align-items: center;
		padding-top: 5px;
	}
	.imgPreview {
		width: 500px;
		margin-top: 1%;
		margin-left: 2%;
		padding-top: 0;
	}
	.imageEditForm {
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding-top: 0;
		margin-top: 0;
	}
	#captionTextarea {
		width: 50%;
		margin-left: auto;
		margin-right: auto;
	}

	.imageEditForm input {
		width: 30%;
		height: 25px;
		font-size: 20px;
		padding: 15px;
		box-sizing: border-box;
	}
	#makeAlbumImageCheck {
		transform: scale(0.8);
		margin-left: auto;
		margin-right: auto;
	}
	.imageEditForm select {
		width: 30%;
		height: 35px;
		font-size: 20px;
	}

	.imageEditFormBtnContainer {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 50%;
		margin-left: auto;
		margin-right: auto;
	}
	.imageEditFormBtnContainer button {
		width: 30%;
		border-radius: 25px;
	}
}
