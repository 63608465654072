.updateProfile {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 100px;
	background-color: white;
	text-align: center;
	-webkit-animation: fade-in 1s linear;
	animation: fade-in 1s linear;
}

.updateProfile input {
	width: 300px;
	height: 25px;
	margin: 10px;
	padding: 5px;
	border: 1px solid black;
	font-size: 20px;
}

.updateProfile button {
	width: 150px;
	height: 25px;
	border-radius: 10px 10px 10px 10px;
	background: transparent;
	margin: 5px;
}
