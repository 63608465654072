.login-form-container {
	position: relative;
	width: 100%;
	height: auto;
	padding-top: 100px;
	padding-bottom: 50px;
	text-align: center;
	background-color: aliceblue;
	border: 1px solid black;
	border-radius: 20px 20px 20px 20px;
	-webkit-animation: fade-in 4s linear;
	animation: fade-in 2s linear;
}

.login-form-container button {
	font-size: 20px;
	background: transparent;
	margin-top: 10px;
	padding: 5px;
	border: 1px solid black;
	border-radius: 10px;
	cursor: pointer;
}

.login-form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.login-form label {
	width: 100%;
}

.loginFormInput {
	width: 90%;
	height: 30px;
	margin-top: 10px;
	margin-bottom: 10px;
	border: 1px solid black;
}

#loginFormBtn {
	display: block;
	padding: 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
	font-size: 25px;
	background-color: transparent;
	border: 1px solid black;
	border-radius: 20px 20px 20px 20px;
	width: 50%;
}

@media only screen and (min-width: 700px) {
	.login-form-container {
		position: absolute;
		padding: 20px;
		margin-top: 150px;
		width: 25%;
		margin-left: 36.5%;
	}
}
