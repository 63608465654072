nav {
	padding: 0;
	margin: 0;
	text-transform: uppercase;
}

.navBar {
	position: fixed;
	width: 100%;
	height: 50px;
	background-color: #2d2d44;
	padding: 0;
	margin: 0;
	z-index: 1;
	-webkit-animation-name: slide-down; /* Safari 4.0 - 8.0 */
	-webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
	animation-name: slide-down;
	animation-duration: 4s;
}

.navBar ul {
	list-style: none;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0;
	margin: 0;
}

.navBar ul li a {
	text-decoration: none;
	color: white;
	margin-left: 10px;
	margin-right: 10px;
}

.navBar img {
	height: 40px;
	padding: 0;
	margin-top: 5px;
}
