/* Safari 4.0 - 8.0 */
@-webkit-keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* Standard syntax */
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fade-out {
	100% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
}
@keyframes fade-out {
	100% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes slide-down {
	0% {
		transform: translateY(-100px);
	}
	100% {
		transform: translateY(0px);
	}
}

/* Standard syntax */
@keyframes slide-down {
	0% {
		transform: translateY(-100px);
	}
	100% {
		transform: translateY(0px);
	}
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes slide-up {
	0% {
		transform: translateY(100px);
	}
	100% {
		transform: translateY(0px);
	}
}

/* Standard syntax */
@keyframes slide-up {
	0% {
		transform: translateY(500px);
	}
	100% {
		transform: translateY(0px);
	}
}

@-webkit-keyframes lock {
	0% {
		transform: translateY(0px);
	}
	100% {
		transform: translateY(100px);
	}
}

@keyframes lock {
	0% {
		transform: translateY(0px);
	}
	100% {
		transform: translateY(60px);
	}
}
