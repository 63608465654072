.editAlbumPageContainer {
	width: 100%;
	height: 800px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	background-color: aliceblue;
	padding-top: 80px;
	padding-bottom: 15px;
	text-align: center;
	-webkit-animation: fade-in 1s linear;
	animation: fade-in 1s linear;
}

.editAlbumPageContainer form {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
}

.editAlbumPageContainer input {
	border: 1px black solid;
	width: 200px;
	height: 30px;
	text-align: center;
	font-size: 20px;
	margin: 15px;
}

.editAlbumPageContainer button {
	width: 200px;
	height: 30px;
	border: 1px black solid;
	border-radius: 10px;
	margin: 8px;
	font-size: 15px;
	background-color: transparent;
	cursor: pointer;
}

@media only screen and (min-width: 700px) {
	.editAlbumPageContainer {
		width: 30%;
		height: 450px;
		border: 1px solid black;
		border-radius: 25px;
	}
}
