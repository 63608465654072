body {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'PT Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 20px;
}
html {
	margin: 0;
	padding: 0;
}
