.grid-container {
	width: 100%;
	display: grid;
	grid-template-columns: auto auto auto;
	margin-bottom: 20px;
}

@media only screen and (min-width: 700px) {
	.grid-container {
		display: flex;
		flex-wrap: wrap;
		padding: 50px;
		margin: 0;
	}
}
