.imageUploadForm {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	margin-bottom: 25px;
	-webkit-animation: fade-in 1s linear;
	animation: fade-in 1s linear;
}

.uploadFormInput {
	width: 100%;
	height: 40px;
	text-align: left;
	font-size: 20px;
	border: 1px solid black;
}

label {
	margin: 15px;
}

.uploadImgPreview {
	width: 100%;
}
#commentsForImage {
	text-align: left;
	height: 150px;
	width: 100%;
}

#uploadFormBtn {
	display: block;
	width: 90%;
	height: 40px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	padding: 5px;
	text-align: center;
	font-size: 20px;
	border: 1px solid black;
	background-color: transparent;
	cursor: pointer;
}

#returnHomeBtn {
	display: block;
	width: 90%;
	height: 40px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	padding: 5px;
	text-align: center;
	font-size: 20px;
	border: 1px solid black;
	background-color: transparent;
	cursor: pointer;
}

@media only screen and (min-width: 700px) {
	.uploadFormContainer {
		width: 65%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		padding-top: 100px;
	}

	.imageUploadForm {
		width: 100%;
		flex-direction: column;
	}

	.imageUploadForm button {
		width: 150px;
		border-radius: 10px;
	}

	.uploadImgPreview {
		width: 50%;
		margin: 1%;
	}
}
