.deleteConfirmImage {
	width: 100%;
	margin-top: -190px;
	font-size: 30px;
	z-index: 10;
	position: absolute;
	background-color: rgba(240, 248, 255, 1);
	border: 1px solid black;
	border-radius: 25px;
}

.deleteConfirmImage button {
	width: 150px;
	height: 45px;
	font-size: 25px;
	border-radius: 25px;
	margin: 15px;
	background-color: white;
}

.deleteConfirmAlbum {
    width: 100%;
    height: 300px;
	margin-top: 120px;
	font-size: 30px;
	z-index: 10;
	position: absolute;
	background-color: rgba(240, 248, 255, 1);
}

.deleteConfirmAlbum button {
	width: 150px;
	height: 45px;
	font-size: 25px;
	border-radius: 25px;
	margin: 15px;
	background-color: white;
}

@media only screen and (min-width: 700px) {
	.deleteConfirmImage {
		width: 60%;
		height: 200px;
		margin-top: 0px;
		font-size: 30px;
		background-color: rgba(240, 248, 255, 0.9);
		border: 1px solid black;
		border-radius: 25px;
	}

	.deleteConfirmImage button {
		width: 150px;
		height: 45px;
		font-size: 25px;
		border-radius: 25px;
		margin: 15px;
	}

	.deleteConfirmAlbum {
		box-sizing: border-box;
		padding: 10px;
		width: 100%;
		height: 270px;
		margin-top: 110px;
		font-size: 30px;
		background-color: rgba(240, 248, 255, 1);
	}

	.deleteConfirmAlbum button {
		width: 150px;
		height: 45px;
		font-size: 25px;
		border-radius: 25px;
		margin: 15px;
	}
}
