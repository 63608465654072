.image-page-container {
	width: 100%;
	padding-top: 50px;
	margin-bottom: 25px;
	text-align: center;
	-webkit-animation: fade-in 1s linear;
	animation: fade-in 1s linear;
}

.singleImg {
	width: 100%;
	margin: 0;
}

#imagePageBackBtn {
	width: 50px;
	height: 50px;
	float: left;
	font-size: 45px;
	cursor: pointer;
	padding: 0;
	margin-top: 50px;
	background: transparent;
	border: none;
}

.imageButtons {
	width: 100%;
	display: block;
}

.imageButtons button {
	cursor: pointer;
	width: 90%;
	height: 50px;
	border: 1px solid black;
	background-color: transparent;
	font-size: 30px;
	margin-top: 35px;
}

.imageLoading p {
	margin: 0;
	padding-top: 200px;
	text-align: center;
}

@media only screen and (min-width: 700px) {
	.image-page-container {
		width: 60%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 100px;
		margin-left: auto;
		margin-right: auto;
	}
	.singleImg {
		width: 50%;
		margin-top: 1%;
		margin-left: 2%;
	}
	.image-info {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.image-info button {
		border-radius: 10px;
		width: 150px;
		margin: 20px;
	}
	#imagePageBackBtn {
		margin-top: 50px;
		background-color: transparent;
		border: none;
	}
}
