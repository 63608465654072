.albumPage-container {
	width: 100%;
	padding-top: 30px;
	font-size: 30px;
	-webkit-animation: fade-in 1s linear;
	animation: fade-in 1s linear;
}

#albumBtnDiv1 {
	display: inline-flex;
}
#albumBtnDiv1 p {
	margin-top: 63px;
}

#albumBtnDiv2 {
	display: flex;
	justify-content: space-around;
	align-items: baseline;
}

.albumPageBtnContainer {
	width: 100%;
	padding-top: 0px;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

img#removeAlbumBtn {
	cursor: pointer;
	margin-right: 10px;
	height: 50px;
	width: 40px;
	background-color: transparent;
}
img#uploadAlbumImg {
	margin-right: 10px;
	height: 60px;
	width: 60px;
}

img#editAlbumImg {
	margin-right: 10px;
	height: 60px;
	width: 60px;
}

@media only screen and (min-width: 700px) {
	.albumPageBtnContainer {
		margin-bottom: 0px;
	}
	#removeAlbumBtn {
		width: 200px;
	}
}
