.albums-bar {
	width: 100%;
	height: 150px;
}

.albums-bar ul {
	box-sizing: border-box;
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: space-between;
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
}

.albums-bar ul li {
	line-height: 0px;
	font-size: 25px;
	padding-left: 5px;
	padding-right: 5px;
}
.albums-bar ul li link {
	text-decoration: none;
	text-decoration-line: none;
}

.albumImg {
	box-sizing: border-box;
	border: 3px solid black;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	overflow: hidden;
	padding: 5px;
}

@media only screen and (min-width: 700px) {
	.albums-bar {
		margin-top: 5%;
	}
	.albums-bar ul {
		box-sizing: border-box;
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
	}
}
