.landing-content {
	position: relative;
	padding-top: 50px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.landingText {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
}
.landing-content h4 {
	margin-top: -10px;
}
.landing-content p {
	text-align: center;
	padding: 10px;
	margin-top: -10px;
	border-bottom: 1px solid black;
}

.landing-example {
	width: 100%;
}

.landing-example p {
	margin-left: auto;
	margin-right: auto;
}
.landing-example img {
	width: 100%;
}

.cameraLockImage {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
}
#lock {
	position: absolute;
	width: 250px;
	height: auto;
	animation: lock 1s 3s linear forwards;
	z-index: -10;
}
#camera {
	width: 300px;
	margin-top: 175px;
}

@media only screen and (min-width: 700px) {
	.landing-content {
		width: 70%;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		flex-direction: row;
		padding: 50px;
		padding-top: 100px;
		margin-left: auto;
		margin-right: auto;
	}

	.landing-content p {
		border: none;
	}
	.cameraLockImage {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
}
