.gridImg {
	position: absolute;
	left: 50%;
	top: 50%;
	height: 100%;
	width: auto;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.image-container {
	position: relative;
	width: 120px;
	height: 120px;
	overflow: hidden;
	border: 1px solid white;
}

@media only screen and (min-width: 700px) {
	.image-container {
		width: 200px;
		height: 200px;
	}
}
