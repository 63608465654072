.add-album-container {
	width: 100%;
	padding-top: 100px;
	text-align: center;
	-webkit-animation: fade-in 1s linear;
	animation: fade-in 1s linear;
}

.addFolderForm {
	width: 100%;
}

.addFolderForm label {
	font-size: 30px;
	margin: 5px;
	padding-bottom: 10px;
}
.addFolderForm input {
	border: 1px solid black;
	width: 90%;
	height: 50px;
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: auto;
	margin-right: auto;
	font-size: 20px;
	padding: 5px;
	box-sizing: border-box;
}

.albumImgPreview {
	box-sizing: border-box;
	border: 2px solid black;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	overflow: hidden;
	padding: 5px;
}

.addFolderForm button {
	width: 90%;
	height: 50px;
	font-size: 30px;
	border: 1px solid black;
	background-color: transparent;
	margin-top: 15px;
	cursor: pointer;
}

@media only screen and (min-width: 700px) {
	.add-album-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.addFolderForm {
		width: 30%;
	}
	.addFolderForm input {
		height: 40px;
		font-size: 20px;
	}
}
