.reg-form {
	position: relative;
	padding-top: 100px;
	width: 100%;
	height: auto;
	text-align: center;
	background-color: white;
	background-color: aliceblue;
	border-radius: 20px 20px 20px 20px;
	-webkit-animation: fade-in 4s linear;
	animation: fade-in 2s linear;
}

.signupForm {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.signupForm label {
	width: 100%;
}

.signupFormInput {
	width: 90%;
	height: 30px;
	margin-top: 5px;
	margin-bottom: 5px;
	border: 1px solid black;
}

#signupFormSubmit {
	display: block;
	padding: 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
	font-size: 25px;
	background-color: transparent;
	border: 1px solid black;
	border-radius: 20px 20px 20px 20px;
	width: 50%;
}

#signupFormReset {
	display: block;
	padding: 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
	font-size: 25px;
	background-color: transparent;
	border: 1px solid black;
	border-radius: 20px 20px 20px 20px;
	width: 25%;
}

@media only screen and (min-width: 700px) {
	.reg-form {
		position: absolute;
		padding: 20px;
		margin-top: 80px;
		width: 25%;
		margin-left: 36.5%;
		border: 1px solid black;
	}
}
